/**
 * WIP
 * This will almost certainly not exist here as this file in the end, but
 * is a jumping off point for me to figure out some of this invalidation
 * stuff.
 */

import log from '@/utils/logging';

type mapApiResourceUrlToResourceOptions = {
  limitToPathSegments?: number;
};

/**
 * Essentially this function just maps one of our API URLS e.g.
 * https://yc1.api.nextgen.dev.lo-q.com/v2/attractions
 * into just the resource portion (I.E. the path without the version segment).
 * So the above would become 'attractions'.
 *
 * Since all this does is that mapping, it will map over any extra "nesting"
 * done with forward slashes, e.g https://yc1.api.nextgen.dev.lo-q.com/v2/attractions/12345
 * would map to 'attractions/12345'.
 */
export function mapApiResourceUrlToResource(
  url: string,
  options?: mapApiResourceUrlToResourceOptions,
): string[] | undefined {
  if (!url || url.trim().length === 0) {
    return;
  }

  const limitToPathSegments = options?.limitToPathSegments;

  try {
    const uri = new URL(url);
    const path = uri.pathname; // /v2/attractions

    // This feels kind of brittle, but sticking with it for now :/
    const regex = /\/v(\d+)(\/(.*))*/;
    const match = regex.exec(path);

    if (!match || match.length < 4) {
      return;
    }

    const resourcePath = match[3];

    // const apiVersion = match[1];
    // log.debug('mapApiResourceUrlToQueryKey: url parsing', {
    //   apiVersion,
    //   resourcePath,
    // });

    if (resourcePath === undefined || resourcePath === '') {
      return ['root'];
    }

    // Sub resource parsing
    // This is definitely not finished
    const resourceParts = resourcePath.split('/');

    if (limitToPathSegments !== undefined) {
      return resourceParts.slice(0, limitToPathSegments);
    }

    return resourceParts;
  } catch (error) {
    log.error('An error occurred mapping resource url to a query key', error);
    return;
  }
}
