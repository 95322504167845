import { Skeleton, Typography } from '@pebl/ui';
import parse from 'html-react-parser';

import { ApiResourceAction } from '@/api/types';
import { ActionList } from '@/features/actions/components/action-list';
import { setOnboardingOverlayDismissedTimestamp } from '@/stores/qng-data-store';
import { CommonViewProps } from '@/types/core';
import { TranslationComponentProps } from '@/types/translation';

type PaymentViewProps = {
  actions?: ApiResourceAction[];
  name?: string;
  description?: string;
} & TranslationComponentProps &
  CommonViewProps;

export function PaymentView({
  actions,
  description,
  loading,
  name,
}: PaymentViewProps) {
  return (
    <div className={`payment page-container pb-20`}>
      <div className="payment-status flex flex-col gap-2">
        {loading ? (
          <Skeleton
            variant="text"
            data-testid="payment-client-name"
            className="h-8 w-1/4"
          />
        ) : (
          name && <Typography variant="h3">{parse(name)}</Typography>
        )}
        {loading ? (
          <Skeleton
            variant="text"
            data-testid="payment-client-description"
            className="h-6"
          />
        ) : (
          description && (
            <Typography variant="body1">{parse(description)}</Typography>
          )
        )}
      </div>
      {/* Action Buttons */}
      <div className="payment-action-area fixed inset-x-0 bottom-0 p-4">
        {loading ? (
          <Skeleton
            variant="button"
            className="w-full"
            data-testid="payment-client-action-button"
          />
        ) : (
          actions && (
            <div className="payment-action-buttons w-full">
              <ActionList
                actions={actions}
                onActionSuccess={() => setOnboardingOverlayDismissedTimestamp()}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
}
