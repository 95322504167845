import { ResourceQueryKeyFn } from '@/types/invalidation';

import { axios } from '../../axios';
import { getCommonHeaders } from '../../utils/headers';
import { ApiGetPaymentResponse } from './types';

const ENDPOINT = () => `/shop/payment`;

export const resourceQueryKey: ResourceQueryKeyFn = () => ['shop', 'payment'];

export async function getPayment(
  options: {
    signal?: AbortSignal;
    authToken?: string | null;
  } = {},
): Promise<ApiGetPaymentResponse> {
  const { data } = await axios.get(ENDPOINT(), {
    signal: options?.signal,
    headers: getCommonHeaders({ authToken: options.authToken }),
  });

  return data;
}
