import log from '@/utils/logging';

type loadThemeLocalisationOptions = {
  // Any prefix to add to the request e.g. themes/
  prefix?: string;

  // The request path to the theme localisation file
  // e.g. sixflags/translation.json
  // Or undefined if no theme is set
  translationFilePath?: string;
};

export async function loadThemeLocalisation({
  prefix = 'themes/',
  translationFilePath,
}: loadThemeLocalisationOptions): Promise<Record<string, unknown> | undefined> {
  if (!translationFilePath) {
    return undefined;
  }

  const targetUrl = `${prefix}${translationFilePath}`;

  log.debug('Loading theme localisation', targetUrl);

  try {
    const response = await fetch(targetUrl);
    if (response.ok) {
      const data = await response.json();
      return data;
    }

    log.warn(
      'Failed to load theme localisation',
      response.status,
      response.statusText,
    );

    return undefined;
  } catch (error) {
    log.error('Failed to load theme localisation', error);

    return undefined;
  }
}
