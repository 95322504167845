import Hotjar from '@hotjar/browser';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';

import { QNG_COOKIE_NAME } from '@/utils/constants';
import log from '@/utils/logging';

type AnalyticsProviderProps = {
  /**
   * The Hotjar ID this deployment should use.
   * If not set then won't setup HotJar.
   *
   * You can also explicitly disable hotjar by setting the `enableHotjar` prop to `false`.
   *
   * This is retrieved from / set in the Versions repository host.json file on the
   * `config.hotjarId` property of a host or variant.
   */
  hotjarId?: number; // Integer

  /**
   * Whether to enable Hotjar or not (default: true).
   * Requires `hotjarId` to be set to actually function.
   * The main reason for this is to allow enabling/disabling hotjar without having to
   * completely remove the ID from the deployment.
   */
  enableHotjar?: boolean;

  /**
   * Target version of hotjar.
   * We probably have no reason to ever change this
   * Default is 6.
   */
  hotjarVersion?: number;

  /**
   * The name of the cookie that determines if analytics are allowed.
   *
   * This is set elsewhere on the site (e.g. cookie policy page).
   */
  analyticsAllowedCookieName?: string;

  /**
   * Whether to enable debug mode for Hotjar.
   * This is useful for development environments.
   */
  debugHotjar?: boolean;
};

/**
 * Our analytics provider for the site.
 * Currently this just sets up Hotjar, but in the future
 * we could add Google Analytics etc setup here too.
 *
 * Future Improvements: This could become a context and provide lower components
 *                      a common interface to interact with Hotjar etc.
 */
export function AnalyticsProvider({
  analyticsAllowedCookieName = QNG_COOKIE_NAME,
  debugHotjar = false,
  enableHotjar = true,
  hotjarId,
  hotjarVersion = 6,
}: AnalyticsProviderProps) {
  // I'm not a fan that we have to interact with the cookies like this
  // here and would much prefer this be in Zustand (which could then handle
  // persisting it to a cookie etc).
  // TODO: Re-asses this in the future.
  const [cookies] = useCookies([analyticsAllowedCookieName], {
    doNotParse: true,
  });

  const analyticsAllowed = cookies[analyticsAllowedCookieName] === 'true';

  useEffect(() => {
    if (!analyticsAllowed) {
      log.info(
        'Analytics are disabled (cookie disallows), skipping setup.',
        analyticsAllowedCookieName,
        cookies[analyticsAllowedCookieName],
      );
      return;
    }

    if (hotjarId !== undefined && isNaN(hotjarId)) {
      log.error('Invalid Hotjar ID provided:', hotjarId);
    }

    if (Hotjar.isReady()) {
      log.warn('Hotjar is already set up, skipping.');
      return;
    }

    if (hotjarId && enableHotjar === true) {
      log.info('Setting up Hotjar');

      Hotjar.init(hotjarId, hotjarVersion, {
        debug: debugHotjar,
      });
    }

    // There is no way to cleanup Hotjar unfortunately.
  }, [
    analyticsAllowed,
    analyticsAllowedCookieName,
    cookies,
    debugHotjar,
    enableHotjar,
    hotjarId,
    hotjarVersion,
  ]);

  return <div />;
}
