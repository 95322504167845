/**
 * A utility function to allow us to check if a string is empty or whitespace.
 *
 * @param str The string to check.
 * @returns True if the string is empty or whitespace, false otherwise.
 */
export function isStringWhitespace(str: string): boolean {
  return typeof str === 'string' && str.trim().length === 0;
}

/**
 * A utility function to allow us to check if a string is empty, whitespace, or undefined.
 *
 * @param str The string to check.
 * @returns True if the string is empty, whitespace, or undefined, false otherwise.
 */
export function isStringWhitespaceOrUndefined(
  str: string | undefined,
): boolean {
  return str === undefined || isStringWhitespace(str);
}

/**
 * A utility function to convert a number (as a string) into a number where possible.
 *
 * @param str - The string to convert to a number.
 * @returns The number if the string can be converted, otherwise undefined.
 */
export function stringToNumber(
  str?: string,
  defaultValue: number | undefined = undefined,
): number | undefined {
  if (str === undefined || str === null) {
    return defaultValue;
  }

  // Even though typescript won't allow it at build time, we should check that
  // str is not already a number, if it is just return it as is
  if (typeof str === 'number') {
    return str;
  }

  const trimmedString = str.trim();
  if (trimmedString.length === 0) {
    return defaultValue;
  }

  const num = Number(trimmedString);

  return isNaN(num) ? defaultValue : num;
}

/**
 * A utility function to convert a string to a boolean.
 *
 * @param str - The string to convert to a boolean.
 * @returns True if the string is 'true' (case insensitive), false if it is 'false' (case insensitive). Otherwise undefined.
 */
export function stringToBoolean(str?: string): boolean | undefined {
  if (str === undefined || str === null) {
    return undefined;
  }

  if (typeof str === 'boolean') {
    return str;
  }

  if (typeof str !== 'string') {
    return undefined;
  }

  const trimmedString = str.trim().toLowerCase();
  if (trimmedString === 'true') {
    return true;
  }

  if (trimmedString === 'false') {
    return false;
  }

  return undefined;
}
