import Axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';

import log from '@/utils/logging';

import { unauthorizedErrorMiddleware } from './utils/unauthorized-error-middleware';

/**
 * This is only for development / storybook, where we need any
 * localhost URIs to be http, not https.
 */
function getProtocol(apiUri: string | undefined): string {
  return apiUri?.startsWith('localhost') ? 'http' : 'https';
}

// The Base URL is injected in from CloudFront via the configuration.js file
// which sets the window.globalQngConfig object, containing 'apiUri'
export const axios = Axios.create({
  baseURL: `${getProtocol(window.globalQngConfig.apiUri)}://${window.globalQngConfig.apiUri}`,
});

axios.interceptors.request.use(requestInterceptor, requestErrorInterceptor);
axios.interceptors.response.use(responseInterceptor, responseErrorInterceptor);

/** Interceptor definitions */

function requestInterceptor(config: InternalAxiosRequestConfig) {
  // NOTE: We can do any global request augmentation here
  return config;
}

function requestErrorInterceptor(error: unknown) {
  // NOTE: We can do any global request error handling here
  return Promise.reject(error);
}

/**
 * An interceptor to allow us to handle responses globally and
 * do any necessary processing.
 */
function responseInterceptor(value: AxiosResponse<unknown, unknown>) {
  return value;
}

/**
 * An interceptor to allow us to handle response errors globally and
 * do any necessary processing.
 */
function responseErrorInterceptor(error: unknown) {
  log.debug('Response error', error);

  // Use our unauthorized middleware here.
  const resp = unauthorizedErrorMiddleware(error);
  // return resp;

  return Promise.reject(resp);
}
