import { Input, VisibilityIcon } from '@pebl/ui';
import { useState } from 'react';

import { CommonActionPropertyViewProps } from '../../../types';

export function ActionPropertyInputPasswordView({
  allowShowPassword = true,
  defaultValue,
  disabled,
  error,
  helperText,
  innerRef,
  label,
  name,
  onBlur,
  onChange,
  value,
}: {
  error?: boolean;
  label?: string;
  helperText?: string;
  allowShowPassword?: boolean;
} & CommonActionPropertyViewProps<string>) {
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  return (
    <Input
      type={passwordVisible ? 'text' : 'password'}
      autoComplete="current-password"
      label={label}
      error={error}
      name={name}
      value={value}
      defaultValue={defaultValue}
      disabled={disabled}
      onChange={(e) => onChange?.(e.target.value)}
      onBlur={onBlur}
      helperText={helperText}
      className="qng-action-property-input-password"
      ref={innerRef}
      suffixIcon={
        allowShowPassword ? (
          <VisibilityIcon outlined className="size-6" />
        ) : undefined
      }
      onSuffixIconClick={() => setPasswordVisible((prev) => !prev)}
      //
      // Data Fields - Primarily for debugging / testing
      //
      data-action-option-type="string"
      data-action-option-format="password"
    />
  );
}
