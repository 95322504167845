import { Skeleton } from '@pebl/ui';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

interface ImageProps {
  /**
   * The URL of the image.
   */
  src?: string;
  /**
   * The alt text for the image.
   */
  alt?: string;
  /**
   * Additional classes to apply to the image.
   */
  className?: string;
  /**
   * Explicitly show this image in a loading skeleton state.
   */
  loading?: boolean;
  /**
   * Whether to apply the loading=lazy html attribute to the image.
   */
  loadMethod?: 'lazy' | 'eager' | undefined;
}

/**
 * Keep all our uses of images consistent by always using this component.
 * This will allow us to implement new loading logic or other features
 * in a single place.
 *
 * DEV NOTE: We want to see if we can nicely implement a implicit loading
 * state for images. The only way I know of to do this is to load the image
 * "off DOM" in a hidden img tag, and then swap it in once it's loaded, but
 * that may be old knowledge I have not kept up with.
 *
 * ⚠️ NOTE: This is still quite Work in Progress, it may change quite drastically
 *          as I try to improve the perceived performance of image loads.
 *          If things go bad I may just revert it to being a glorified wrapper
 *          around an img tag.
 *
 */
export function AsyncImage({
  alt,
  className,
  loadMethod,
  loading,
  src,
}: ImageProps) {
  const [loadError, setLoadError] = useState<Error | undefined>(undefined);
  const [loadedSrc, setLoadedSrc] = useState<string | undefined>(undefined);

  useEffect(() => {
    async function loadImage() {
      if (!src) {
        // log.debug('No src provided to AsyncImage');
        return;
      }

      return new Promise((resolve, reject) => {
        // log.debug('Loading image off DOM ...', src);
        const img = new Image();
        img.src = src;
        if (img.complete) {
          // log.debug('Image already cached', src);
          resolve(img);
        } else {
          img.onload = () => {
            // log.debug('Image loaded off DOM', src);
            resolve(img);
          };
          img.onerror = () => {
            // log.error('Failed to load image off DOM', src);
            reject(new Error('Failed to load image'));
          };
        }
      });
    }

    loadImage()
      .then(() => {
        setLoadedSrc(src);
        setLoadError(undefined);
      })
      .catch((err) => {
        setLoadError(err);
        setLoadedSrc(undefined);
      });

    // NOTE: Do we need a cleanup return here to avoid memory leaks, I *think* it may be fine, need to look into this
  }, [src]);

  if (!loading && loadError) {
    // TODO: For now it just continues showing the same skeleton, but this should
    // probably have a nicer indication an image failed to load for some reason,
    // instead of giving the user false hope.
    return <Skeleton className={className} alt={alt} />;
  }

  return loading || !loadedSrc ? (
    <Skeleton
      className={clsx('qng-async-image-loading', className)}
      data-testid="async-image-loading"
      data-loadingsrc={src}
      alt={alt}
    />
  ) : (
    // TODO: It would be nicer if the fade in ONLY happened after a load and not on every mount
    //       but that's not that easy to actually do with this setup.
    <motion.span
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={`qng-async-image-anim-wrapper`}
    >
      <img
        data-testid="async-image-loaded"
        src={loadedSrc}
        alt={alt}
        className={clsx('qng-async-image', className)}
        loading={loadMethod}
      />
    </motion.span>
  );
}
