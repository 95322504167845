import { Button } from '@pebl/ui';
import { forwardRef, useCallback, useImperativeHandle } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ApiResourceAction } from '@/api/types';

import { mapActionOptionsToComponent } from '../../utils/map-action-options';

type ActionFormBuilderProps = {
  /**
   * The action to render the form for.
   * If no action is provided or it's undefined then nothing is rendered.
   */
  action?: ApiResourceAction;

  /**
   * Is the action execution currently pending from a previous request?
   * If so then the form should likely prevent re-submission and indicate
   * that it is pending.
   */
  isPending?: boolean;

  /**
   * A callback to invoke when the form want to submit, I.E. call this when
   * the submit button is clicked.
   *
   * @param formData Any form data that should be submit with the action.
   */
  submitAction?: (formData: unknown) => void;

  /**
   * A callback function that is called if the action is cancelled via
   * any cancel mechanic the form may provide (or the form is closed etc).
   */
  onActionCancelled?: () => void;
};

/**
 * This is the type for the custom reference we expose via the useImperativeHandle
 * call in the ActionFormBuilder. The main purpose of this is to expose form specific
 * methods (such as setting/clearing errors on fields) to the parent component where
 * the form is actually being processed.
 */
export type ActionFormBuilderRef = {
  /**
   * Set a specific error on a field in the form.
   */
  setError: (name: string, error: { type: string; message: string }) => void;
  /**
   * Clear errors from the form.
   */
  clearErrors: (name?: string | string[]) => void;
  /**
   * Reset the form.
   */
  reset: () => void;
};

export const ActionFormBuilder = forwardRef<
  ActionFormBuilderRef,
  ActionFormBuilderProps
>(function ActionFormBuilder(
  {
    action,
    isPending,
    onActionCancelled,
    submitAction,
  }: ActionFormBuilderProps,
  ref,
) {
  const { t } = useTranslation();
  const formMethods = useForm();
  const {
    clearErrors,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    setError,
  } = formMethods;

  // TODO: When the action changes (chained actions) we need to reset the form, we could either
  // do that here with reset() in a useEffect, OR add reset to the imperativeHandle
  // and then let the parent component call it when it actually changes the action
  // due to a changed action.

  useImperativeHandle<ActionFormBuilderRef, ActionFormBuilderRef>(
    ref,
    () => ({
      clearErrors,
      setError,
      reset,
    }),
    [clearErrors, setError, reset],
  );

  const onSubmitAction = useCallback(
    (formData: unknown) => {
      // Clear any existing "custom" errors we have set from a previous request.
      clearErrors();

      // Pass it up to be handled by the parent component.
      submitAction?.(formData);
    },
    [clearErrors, submitAction],
  );

  if (!action) {
    return false;
  }

  return (
    <FormProvider {...formMethods}>
      <form
        aria-label="action-form" // Needed for tests
        onSubmit={handleSubmit(onSubmitAction)}
        className="flex flex-col flex-wrap gap-4"
      >
        {Object.entries(action?.options?.properties ?? {}).map(
          ([key, value]) => {
            const isPropertyRequired =
              action?.options?.required?.includes(key) ?? false;
            const isPropertyInError = errors[key] !== undefined ? true : false;
            const propertyErrorMessage = isPropertyInError
              ? errors[key]?.message
              : undefined;

            return (
              <div key={key} className="">
                {mapActionOptionsToComponent(key, value, {
                  required: isPropertyRequired,
                  isError: isPropertyInError,
                  errorMessage: propertyErrorMessage?.toString(), // ! This toString() is sketchy!!
                })}
              </div>
            );
          },
        )}
        <Button
          data-testid="action-form-submit"
          type="submit"
          isLoading={isPending}
          disabled={isPending || !isValid || action?.disabled}
          loadingText={
            action?.submit ??
            action?.text ??
            t('actions.default_submit_button_pending')
          }
          className="w-full"
        >
          {action?.submit ?? action?.text ?? 'actions.default_submit_button'}
        </Button>

        {action?.cancel && (
          <Button
            data-testid="action-form-cancel"
            type="button"
            onClick={onActionCancelled}
            className={`w-full`}
            variant="outline"
          >
            {action?.cancel}
          </Button>
        )}
      </form>
    </FormProvider>
  );
});
