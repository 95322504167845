import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '@pebl/ui';
import clsx from 'clsx';
import { useState } from 'react';

import { mapPreventRedirect } from '@/features/actions/utils/map-prevent-redirect';
import { TranslationComponentProps } from '@/types/translation';
import { hashAction } from '@/utils/hash-action';
import log from '@/utils/logging';

import ActionForm from '../../action-form';
import { ActionListButton } from './action-list-button';
import { CommonActionListDisplayProps } from './types';

export type ActionListSheetSide = 'top' | 'bottom' | 'left' | 'right';

export type ActionListSheetProps = {
  /**
   * If `displayMethod` is set to `sheet`, this prop can be used to set the side
   * from which the sheet should appear.
   * @default 'bottom'
   */
  side?: ActionListSheetSide;

  /**
   * Optionally provide class names to add to the Sheet container
   * used to render the action form (only applicable if `displayMethod` is set to `sheet`)
   */
  sheetClassName?: string;
} & CommonActionListDisplayProps;

/**
 * ⚠️ DO NOT USE THIS COMPONENT DIRECTLY - THIS IS AN INTERNAL COMPONENT
 *
 * Instead use the {@link ActionList} or {@link ActionListLoader} components if you
 * want to display a list of actions or {@link ActionForm} or {@link ActionFormLoader}
 * if you want to display a single action form.
 */
export function ActionListSheet({
  actionFormClassName,
  actions,
  activeInstantAction,
  closeCancelsAction,
  hideTitle,
  isPending,
  onActionCancelled,
  onActionChained,
  onActionError,
  onActionSuccess,
  preventRedirect,
  selectedAction,
  setSelectedAction,
  sheetClassName,
  side = 'bottom',
}: ActionListSheetProps & TranslationComponentProps) {
  const [chainedActionTitle, setChainedActionTitle] = useState<
    string | undefined
  >(undefined);
  const sheetTitle = chainedActionTitle ?? selectedAction?.title ?? '';

  return (
    <Sheet
      onOpenChange={(open) => {
        if (!open) {
          if (closeCancelsAction) {
            log.debug(
              'ActionListSheet: Dialog closed, calling onActionCancelled callback',
            );
            onActionCancelled?.(selectedAction);
          }
          setSelectedAction(undefined);
        }
      }}
    >
      {actions?.map((action) => (
        <ActionListButton
          wrapperType="sheet"
          key={action.key ?? hashAction(action)}
          action={action}
          activeInstantAction={activeInstantAction}
          // selectedAction={selectedAction}
          setSelectedAction={setSelectedAction}
          isPending={isPending && activeInstantAction === action}
        />
      ))}
      {/**
       * NOTE: We may want to add a class that includes the action type for action specific
       * targeting e.g. action-list-sheet-shop.add
       * The ActionForm should already do this for the form, but we wouldn't have any way
       * to customise the actual Sheet per action (if that's even a thing).
       */}
      <SheetContent
        side={side}
        closable
        className={clsx('qng-action-list-sheet', sheetClassName)}
      >
        <SheetHeader>
          {!hideTitle && <SheetTitle>{sheetTitle}</SheetTitle>}
          {/* This DialogDescription is required to prevent warnings, even if we don't use it */}
          <SheetDescription />
        </SheetHeader>
        {selectedAction && (
          // Action forms normally show the title, but we can hide it here
          // if the dialog is showing the header instead.
          <ActionForm
            action={selectedAction}
            className={actionFormClassName}
            hideTitle={!hideTitle}
            preventRedirect={mapPreventRedirect(
              preventRedirect,
              selectedAction,
            )}
            onActionError={(error) => onActionError?.(error, selectedAction)}
            onActionChained={(_previousAction, nextAction) => {
              log.debug(
                'ActionList:onActionChained - Action form indicated chaining',
              );
              setChainedActionTitle(nextAction.title);
              onActionChained?.(_previousAction, nextAction);
            }}
            onActionSuccess={(executionResponse) => {
              log.debug(
                'ActionList:onActionSuccess - Action form indicated success',
              );
              // Close the dialog (this feels a bit sketchy)
              setSelectedAction(undefined);

              // Call the parent's onActionSuccess callback
              onActionSuccess?.(executionResponse, selectedAction);
            }}
            onActionCancelled={() => {
              setSelectedAction(undefined);
              onActionCancelled?.(selectedAction);
            }}
          />
        )}
      </SheetContent>
    </Sheet>
  );
}
