import { Typography, WarningStatusIcon } from '@pebl/ui';

export function Error404() {
  return (
    <div className="qng-error-404 pt-4 text-center">
      <WarningStatusIcon className="mx-auto size-16" />
      <Typography variant="h1">404</Typography>
      <Typography variant="h2">Not Found</Typography>
    </div>
  );
}
