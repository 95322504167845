// The import order of tailwind and our index css below is important!
// eslint-disable-next-line simple-import-sort/imports
import '@pebl/tailwind/css';

import '@/styles/index.css';

import { localStoragePersister, queryClient } from '@/api/query-client';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { AnalyticsProvider } from '@/core/analytics';
import { AppSettingsProvider } from '@/core/app-settings';
import { DynamicTheming } from '@/core/dynamic-theming';
import i18n from '@/i18n/config';
import { RuntimeLocalisation } from '@/i18n/runtime-localisation';
import log from '@/utils/logging';
import { stringToBoolean, stringToNumber } from '@/utils/string-utils';
import { Toaster } from '@pebl/ui';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';

import { AppRouter } from './app-router';

/**
 * Primary entrypoint for the New Guest Website (AKA Wrapper).
 */

async function enableMocking() {
  // We ALWAYS disable mocking in any non development environment.
  if (process.env.NODE_ENV !== 'development') {
    return;
  }

  // API Mocking is opt-in via an environment variable.
  // So people never use it or are confused by it unless they explicitly
  // want to use mocking for development purposes.
  // Set VITE_MSW_MOCKING_ENABLED=true in your .env.local file to enable mocking.
  if (import.meta.env.VITE_MSW_MOCKING_ENABLED !== 'true') {
    return;
  }

  const { worker } = await import('../../api/mocks/browser');

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start({ onUnhandledRequest: 'bypass' });
}

enableMocking().then(() => {
  const root = ReactDOM.createRoot(document.getElementById('root')!);

  // Allow us to override this via build env var if required
  const queryClientPersistMaxAge = stringToNumber(
    import.meta.env.VITE_QUERY_PERSIST_CLIENT_MAX_AGE,
  );

  const debugHotjar = stringToBoolean(import.meta.env.VITE_DEBUG_HOTJAR);

  root.render(
    <React.StrictMode>
      <HelmetProvider>
        <AppSettingsProvider />
        <DynamicTheming prefix="themes/" />
        <AnalyticsProvider
          hotjarId={window.globalQngConfig.hotjarId}
          debugHotjar={debugHotjar}
        />
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{
            persister: localStoragePersister,
            // !!Important, the maxAge must be less than or equal to the gcTime
            // https://tanstack.com/query/latest/docs/framework/react/plugins/persistQueryClient
            maxAge: queryClientPersistMaxAge ?? 600000, // 10 minutes
          }}
          onSuccess={() => {
            log.info('💦 QueryClient rehydrated successfully');

            const queryCache = queryClient.getQueryCache();
            const queryKeys = queryCache
              .getAll()
              .map((cache) => cache.queryKey);

            log.debug('💦 QueryClient rehydrated with keys:', queryKeys);

            log.debug('💦 QueryClient invalidating all queries post-hydration');

            queryClient.invalidateQueries(undefined, { cancelRefetch: false });

            // NOTE: Consider checking for certain keys in the queryClient and if we
            //       didn't hydrate them (e.g. shop items) then do a pre-fetch here.
          }}
        >
          <ReactQueryDevtools initialIsOpen={false} />
          <I18nextProvider i18n={i18n}>
            <RuntimeLocalisation />
            <Toaster>
              <AppRouter />
            </Toaster>
          </I18nextProvider>
        </PersistQueryClientProvider>
      </HelmetProvider>
    </React.StrictMode>,
  );
});
