import { useSuspenseQuery } from '@tanstack/react-query';

import { getPayment, resourceQueryKey } from '@/api/functions/payment';
import { PropsWithAuthToken } from '@/types/auth';

/**
 * This function is used to generate the query key for this query.
 *
 * @param object - Any parameters needed to generate the query key
 * @returns a valid string[] query key, used in the wrapped query further below
 */
export function getQueryKey({ authToken }: PropsWithAuthToken<{}>) {
  return [...resourceQueryKey(), { authToken }];
}

const queryDefinition = ({ authToken }: PropsWithAuthToken<{}>) => ({
  queryKey: getQueryKey({ authToken }),
  queryFn: ({ signal }: { signal: AbortSignal }) =>
    getPayment({ signal, authToken }),
});

export function useGetPayment({ authToken }: PropsWithAuthToken<{}>) {
  const {
    data: queryData,
    isFetching,
    isPending,
    isRefetching,
    isStale,
  } = useSuspenseQuery(queryDefinition({ authToken }));

  const { actions, client_data: clientData, data, links } = queryData;

  return {
    actions,
    clientData,
    data,
    links,
    isFetching,
    isPending,
    isRefetching,
    isStale,
  };
}
